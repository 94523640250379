import React, {useState} from "react"
import styled from 'styled-components'
import useFirebase from '../hooks/useFirebase'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    display: block;
    background-color: #d3252f;
    border-radius: 5px;
    border: none;
    padding: 10px 40px;
    margin: auto auto;
    color: white;
    cursor: pointer;
    outline: none;
    font-weight: bold;
  }

  button[disabled] {
    background-color: lightgrey;
    cursor: none;
  }

  p {
    margin: 0.5em 0;
  }

  form {
    max-width: 90%;
  }

  input {
    width: 600px;
    height: 30px;
    border-radius: 5px;
    outline: none;
    padding: 0 15px;
    max-width: 90%;
    border-style: inset;
  }

  .input-label {
   color: #e0dedf;
  }

  textarea {
    display: block;
    resize: none;
    width: 600px;
    height: 300px;
    margin-bottom: 2em;
    outline: none;
    padding: 10px 15px;
    border-radius: 5px;
    border-width: 2px;
    border-style: inset;
    max-width: 90%;
  }

  input, textarea {
    font-size: 1rem;

  }
`

const TextInput = ({ name, label, type, value, changeHandler }) => (
  <div className="text-input">
    <p className="input-label">{label}</p>
    <input
      name={name}
      type={type}
      value={value}
      onChange={(e) => changeHandler(e)}
    />
  </div>
)

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const firebaseRef = useFirebase();

  const isFormFilled = (name, email, subject, message) => {
    return name.length > 0 &&
      email.length > 0 &&
      subject.length > 0 &&
      message.length > 0;
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (!isFormFilled(name, email, subject, message)) {
      return
    } 

    firebaseRef.firestore()
      .collection('messages')
      .add({
        name: name,
        email: email,
        subject: subject,
        message: message,
        createdAt: new Date()
      }).then(
        () => {
          window.alert('Sent. Thanks for your message.');
        }, (error) => {
          console.error(error)
          window.alert('Error: please check the data and try again')
        }
      )
  }

  return (
    <Container>
      <form onSubmit={(e) => submitForm(e)}>
        <TextInput
          label="Your Name"
          name="name"
          type="text"
          value={name}
          changeHandler={(e) => setName(e.target.value)}
        />
        <TextInput
          label="Email Address"
          type="email"
          name="email"
          value={email}
          changeHandler={(e) => setEmail(e.target.value)}
        />
        <TextInput
          label="Subject"
          type="text"
          name="subject"
          value={subject}
          changeHandler={(e) => setSubject(e.target.value)}
        />
        <p className="input-label">
          Message
        </p>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          type="submit"
          disabled={!isFormFilled(name, email, subject, message)}
        >
          SEND
        </button>
      </form>
    </Container>
  )
}

export default ContactForm